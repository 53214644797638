import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import { authLogoutRequested } from 'redux/duck/authDuck';
import ForgotPasswordDialog from 'component/dialog/ForgotPasswordDialog';

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const ForgotPasswordDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordDialog);

export default ForgotPasswordDialogContainer;
