import React from 'react';
// Dialog
import DialogDict from 'component/dialog';

function CentralizedDialog(props) {
  const { dialog, hideDialog } = props;

  return (
    <>
      {Object.keys(DialogDict).map((dialogKey, index) => {
        const DialogObject = DialogDict[dialogKey];

        if (
          DialogObject &&
          (typeof DialogObject.Component === 'function' ||
            typeof DialogObject.Component === 'object')
        ) {
          const dialogState = dialog[dialogKey];

          // To unmount close dialogs
          if (!dialogState.isOpen) {
            return null;
          }

          return (
            <DialogObject.Component
              key={dialogKey}
              open={dialogState.isOpen}
              params={dialogState.params}
              callback={dialogState.callback}
              onClose={() => {
                hideDialog(dialogKey);
              }}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default CentralizedDialog;
